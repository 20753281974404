import React from 'react'
// import { graphql } from 'gatsby'
// import { MDXRenderer } from 'gatsby-plugin-mdx'

// Components
import Layout from '../components/Layout'

const Testing = () => {
  return (
    <>
      <Layout>
        <header className="masthead" style={{ backgroundColor: '#2f4167' }}>
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="post-heading">
                  <h1>Markdown</h1>
                  <div className="meta">This is a render test</div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <article>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <hr />
                {/* <MDXRenderer>content</MDXRenderer> */}
                test
              </div>
            </div>
          </div>
        </article>
      </Layout>
    </>
  )
}

export default Testing
